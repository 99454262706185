import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconProfileCard.module.css';

const CLOSE = 'close';
const DOWNARROW = "downarrow";
const UPARROW = "uparrow";
const TRASH = "trash";
const SHOWICON = "showicon";
const HIDEICON = "hideicon";

const IconProfileCard = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case CLOSE:
      return (
        <svg className={classes} fill="#000000" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 3.25 3.25" enable-background="new 0 0 52 52" space="preserve"><path d="m1.938 1.587 0.813 -0.819c0.037 -0.037 0.037 -0.094 0 -0.131l-0.125 -0.131c-0.037 -0.037 -0.094 -0.037 -0.131 0L1.675 1.325c-0.025 0.025 -0.063 0.025 -0.087 0L0.769 0.5c-0.037 -0.037 -0.094 -0.037 -0.131 0l-0.131 0.131c-0.037 0.037 -0.037 0.094 0 0.131l0.819 0.819c0.025 0.025 0.025 0.063 0 0.087L0.5 2.494c-0.037 0.037 -0.037 0.094 0 0.131l0.131 0.131c0.037 0.037 0.094 0.037 0.131 0L1.581 1.938c0.025 -0.025 0.063 -0.025 0.087 0l0.819 0.819c0.037 0.037 0.094 0.037 0.131 0L2.75 2.625c0.037 -0.037 0.037 -0.094 0 -0.131L1.938 1.675c-0.025 -0.025 -0.025 -0.063 0 -0.087z" /></svg>
      );
    case SHOWICON:
      return (
        <svg fill="#000000" width="20px" height="20px" viewBox="0 0 0.9 0.9" version="1.1" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"><path d="M0.841 0.438c-0.084 -0.156 -0.232 -0.25 -0.396 -0.25S0.134 0.283 0.05 0.438l-0.007 0.012 0.007 0.012c0.084 0.156 0.232 0.25 0.396 0.25s0.312 -0.093 0.396 -0.25l0.007 -0.012Zm-0.396 0.223C0.304 0.661 0.175 0.582 0.1 0.45c0.075 -0.132 0.204 -0.211 0.345 -0.211S0.714 0.318 0.79 0.45c-0.076 0.132 -0.204 0.211 -0.345 0.211Z" class="clr-i-outline clr-i-outline-path-1" /><path d="M0.452 0.279A0.172 0.172 0 1 0 0.625 0.45a0.172 0.172 0 0 0 -0.173 -0.171Zm0 0.293A0.122 0.122 0 1 1 0.575 0.45a0.122 0.122 0 0 1 -0.123 0.122Z" class="clr-i-outline clr-i-outline-path-2" /><path x="0" y="0" width="36" height="36" fill-opacity="0" d="M0 0H0.9V0.9H0V0z" /></svg>
      );
    case HIDEICON:
      return (
        <svg width="20px" height="20px" viewBox="0 0 0.6 0.6" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="m0.113 0.139 0.058 0.058a0.222 0.222 0 0 0 -0.068 0.096L0.1 0.3l0.003 0.007c0.015 0.038 0.039 0.072 0.07 0.097a0.203 0.203 0 0 0 0.129 0.046c0.035 0 0.068 -0.009 0.097 -0.025l0.063 0.063 0.026 -0.026 -0.058 -0.058 -0.027 -0.027 -0.173 -0.174 -0.028 -0.028L0.139 0.113 0.113 0.139ZM0.197 0.223A0.184 0.184 0 0 0 0.14 0.299c0.013 0.029 0.033 0.056 0.056 0.075a0.165 0.165 0 0 0 0.105 0.038c0.025 0 0.048 -0.005 0.069 -0.015l-0.023 -0.023c-0.014 0.009 -0.03 0.014 -0.047 0.014A0.087 0.087 0 0 1 0.213 0.3c0 -0.017 0.005 -0.033 0.014 -0.047L0.197 0.223Zm0.057 0.057a0.05 0.05 0 0 0 0.065 0.065l-0.065 -0.065Z" fill="#1F2328"/><path d="M0.299 0.151a0.2 0.2 0 0 0 -0.062 0.01l0.03 0.031a0.164 0.164 0 0 1 0.032 -0.003c0.039 0 0.076 0.014 0.105 0.038 0.024 0.019 0.043 0.046 0.056 0.075a0.186 0.186 0 0 1 -0.032 0.052l0.026 0.026a0.224 0.224 0 0 0 0.043 -0.072l0.002 -0.007 -0.003 -0.007c-0.015 -0.038 -0.039 -0.072 -0.07 -0.097a0.203 0.203 0 0 0 -0.129 -0.046Z" fill="#1F2328" /></svg>
      );
    case DOWNARROW:
      return (
        <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 3.25 3.25" enable-background="new 0 0 52 52" space="preserve"><path d="M0.519 0.875h2.212c0.063 0 0.106 0.081 0.056 0.138L1.706 2.337c-0.037 0.05 -0.119 0.05 -0.156 0L0.456 1.012c-0.044 -0.056 -0.006 -0.138 0.063 -0.138z" /></svg>
      );
    case TRASH:
      return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 32 32" enable-background="new 0 0 32 32" space="preserve">
          <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M23,27H11c-1.1,0-2-0.9-2-2V8h16v17
	C25,26.1,24.1,27,23,27z"/>
          <line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="27" y1="8" x2="7" y2="8" />
          <path fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" d="M14,8V6c0-0.6,0.4-1,1-1h4c0.6,0,1,0.4,1,1v2" />
          <line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="17" y1="23" x2="17" y2="12" />
          <line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="21" y1="23" x2="21" y2="12" />
          <line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="13" y1="23" x2="13" y2="12" />
        </svg>
      );
    case UPARROW:
      return (
        <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 3.25 3.25" enable-background="new 0 0 52 52" space="preserve"><path d="M2.731 2.375H0.519c-0.063 0 -0.106 -0.081 -0.056 -0.138l1.081 -1.325c0.037 -0.05 0.119 -0.05 0.156 0l1.094 1.325c0.044 0.056 0.006 0.138 -0.063 0.138z" /></svg>
      );
    default:
      return (
        <svg
          className={classes}
          width="29"
          height="19"
          viewBox="0 0 29 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M26.58 19H2.42A2.4004 2.4004 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4004 2.4004 0 0 1 29 2.38v14.25c-.0165 1.3216-1.0984 2.3811-2.42 2.37zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              fill="#DADDE2"
            />
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              fill="#B2B6C1"
            />
          </g>
        </svg>
      );
  }
};

IconProfileCard.defaultProps = {
  className: null,
  rootClassName: null,
  type: 'default',
};

IconProfileCard.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default IconProfileCard;
