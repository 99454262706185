import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  // {
  //   id: 'default-helsinki',
  //   predictionPlace: {
  //     address: 'Helsinki, Finland',
  //     bounds: new LatLngBounds(new LatLng(60.29783, 25.25448), new LatLng(59.92248, 24.78287)),
  //   },
  // },
  // {
  //   id: 'default-turku',
  //   predictionPlace: {
  //     address: 'Turku, Finland',
  //     bounds: new LatLngBounds(new LatLng(60.53045, 22.38197), new LatLng(60.33361, 22.06644)),
  //   },
  // },
  // {
  //   id: 'default-tampere',
  //   predictionPlace: {
  //     address: 'Tampere, Finland',
  //     bounds: new LatLngBounds(new LatLng(61.83657, 24.11838), new LatLng(61.42728, 23.5422)),
  //   },
  // },
  // {
  //   id: 'default-oulu',
  //   predictionPlace: {
  //     address: 'Oulu, Finland',
  //     bounds: new LatLngBounds(new LatLng(65.56434, 26.77069), new LatLng(64.8443, 24.11494)),
  //   },
  // },
  // {
  //   id: 'default-ruka',
  //   predictionPlace: {
  //     address: 'Ruka, Finland',
  //     bounds: new LatLngBounds(new LatLng(66.16997, 29.16773), new LatLng(66.16095, 29.13572)),
  //   },
  // },
  {
    id: 'default-newyork',
    predictionPlace: {
      address: 'New York City, New York, United States',
      bounds: new LatLngBounds(new LatLng(40.917577, -73.700292), new LatLng(40.477399, -74.2596399)),
    },
  },
  {
    id: 'default-losangeles',
    predictionPlace: {
      address: 'Los Angeles, California, United States',
      bounds: new LatLngBounds(new LatLng(34.161439, -118.126728), new LatLng(33.899991, -118.52144)),
    },
  },
  {
    id: 'default-chicago',
    predictionPlace: {
      address: 'Chicago, Illinois, United States',
      bounds: new LatLngBounds(new LatLng(42.0348953, -87.4969592), new LatLng(41.6326524, -87.869226)),
    },
  },
  {
    id: 'default-houston',
    predictionPlace: {
      address: 'Houston, Texas, United States',
      bounds: new LatLngBounds(new LatLng(30.085555, -95.054091), new LatLng(29.5231884, -95.730396)),
    },
  },
  {
    id: 'default-pheonix',
    predictionPlace: {
      address: 'Phoenix, Arizona, United States',
      bounds: new LatLngBounds(new LatLng(33.871742, -111.925238), new LatLng(33.29026, -112.310885)),
    },
  },
  {
    id: 'default-philadelphia',
    predictionPlace: {
      address: 'Philadelphia, Pennsylvania, United States',
      bounds: new LatLngBounds(new LatLng(40.137992, -74.955763), new LatLng(39.8685868, -75.280266)),
    },
  },
  {
    id: 'default-sandeigo',
    predictionPlace: {
      address: 'San Diego, California, United States',
      bounds: new LatLngBounds(new LatLng(33.072207, -116.847154), new LatLng(32.534251, -117.2872765)),
    },
  },
  {
    id: 'default-sanfrancisco',
    predictionPlace: {
      address: 'San Francisco, California, United States',
      bounds: new LatLngBounds(new LatLng(37.8664883, -122.3010701), new LatLng(37.670769, -122.5446375)),
    },
  },
  {
    id: 'default-miami',
    predictionPlace: {
      address: 'Miami, Florida, United States',
      bounds: new LatLngBounds(new LatLng(25.979434, -80.144468), new LatLng(25.515125, -80.8736)),
    },
  },
  {
    id: 'default-dallas',
    predictionPlace: {  
      address: "Dallas, Texas, United States",
      bounds: new LatLngBounds(new LatLng(33.016499, -96.555516), new LatLng(32.618564, -96.995725)),
    },
  },
];
export default defaultLocations;
