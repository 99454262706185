import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureCurrentUser } from '../../util/data';
import { USERTYPE_MOVER } from '../../util/types';

import css from './UserNav.module.css';

const UserNav = props => {
  const user = ensureCurrentUser(useSelector(state => state?.user?.currentUser )) || {};
  const { userType } = (user && user.id && user.attributes.profile.publicData) || {};
  const { className, rootClassName, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);

   const manageListingsLink = {
    text: <FormattedMessage id="UserNav.yourListings" />,
    selected: currentPage === 'ManageListingsPage',
    linkProps: {
      name: 'ManageListingsPage'
    }
  } 

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  if(userType == USERTYPE_MOVER)
  {
    tabs.unshift(manageListingsLink);
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
