/*
 * Renders a set of options with selected and non-selected values.
 *
 * The corresponding component when selecting the values is
 * FieldCheckboxGroup.
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import includes from 'lodash/includes';

import css from './PropertyGroup.module.css';

const checkSelected = (options, selectedOptions, keyName) => {
  if(keyName == "truck_size"){
    return options.map(option => ({
      key: option.key,
      label: option.label?.split("-")[0],
      subLabel: option.label?.split("-")[1],
      isSelected: includes(selectedOptions, option.key),
    }));
  }else{
    return options.map(option => ({
      key: option.key,
      label: option.label,
      isSelected: includes(selectedOptions, option.key),
    }));
  }
};

const IconCheck = props => {
  const isVisible = props.isVisible;
  const classes = isVisible ? css.checkIcon : classNames(css.checkIcon, css.hidden);

  return (
    <svg width="9" height="9" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <path
        className={css.marketplaceFill}
        d="M2.636621 7.7824771L.3573694 5.6447948c-.4764924-.4739011-.4764924-1.2418639 0-1.7181952.4777142-.473901 1.251098-.473901 1.7288122 0l1.260291 1.1254783L6.1721653.505847C6.565577-.0373166 7.326743-.1636902 7.8777637.227582c.5473554.3912721.6731983 1.150729.2797866 1.6951076L4.4924979 7.631801c-.2199195.306213-.5803433.5067096-.9920816.5067096-.3225487 0-.6328797-.1263736-.8637952-.3560334z"
        fillRule="evenodd"
      />
    </svg>
  );
};

const IconCross = () => {
  return (
    <svg fill="#000000" width="17px" height="17px" viewBox="0 0 21.76 21.76" xmlns="http://www.w3.org/2000/svg"><path fill="red" d="m14.819 16.133 1.314 -1.314L12.194 10.88l3.939 -3.939 -1.314 -1.314L10.88 9.566 6.941 5.627 5.627 6.941 9.566 10.88l-3.939 3.939 1.314 1.314L10.88 12.194z" /></svg>
  );
};

const Item = props => {
  const { label, isSelected, sublabel="", showIconCross, keyName } = props;
  const labelClass = isSelected ? css.selectedLabel : css.notSelectedLabel;
  return (
    <li className={css.item}>
      <span className={css.iconWrapper}>
        {isSelected ? <IconCheck isVisible={isSelected} /> : null}
        {!isSelected && showIconCross ? <IconCross/> : null}
      </span>
      <div className={css.labelWrapper}>
        {keyName == "PrimaryCountries" ? isSelected ? <span className={labelClass}>{label}</span> : null : <span className={labelClass}>{label}</span>}
        {sublabel != "" ? <span className={css.selectedSubLabel}> - {sublabel}</span> : null}
      </div>
    </li>
  );
};

const PropertyGroup = props => {
  const { rootClassName, className, id, options, selectedOptions, twoColumns, keyName } = props;
  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(classes, css.twoColumns) : classes;
  const selectedItems = checkSelected(options, selectedOptions, keyName);
  const checked = selectedItems.sort((a, b)=>{
    if(a.isSelected === b.isSelected){
      return 0;
    }else if(a.isSelected){
      return -1;
    }else{
      return 1;
    }
  });

  return (
    <ul className={listClasses}>
      {checked.map(option =>{
        if(keyName == "truck_size"){
          return <Item key={`${id}.${option.key}`} label={option.label} sublabel={option?.subLabel} isSelected={option.isSelected} showIconCross={true} />
        } else if(keyName == "PrimaryCountries"){
          return <Item key={`${id}.${option.key}`} label={option.label} sublabel={option?.subLabel} isSelected={option.isSelected} showIconCross={false} keyName={keyName} />
        }else{
          return <Item key={`${id}.${option.key}`} label={option.label} isSelected={option.isSelected} showIconCross={true} />
        }
      })}
    </ul>
  );
};

PropertyGroup.defaultProps = {
  rootClassName: null,
  className: null,
  selectedOptions: [],
  twoColumns: false,
};

const { arrayOf, bool, node, shape, string } = PropTypes;

PropertyGroup.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ),
  selectedOptions: arrayOf(string),
  twoColumns: bool,
};

export default PropertyGroup;
